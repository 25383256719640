<!--
PortfolioMixin:
Loads a specific portfolio for pages with router argument "portfolio_id"

Provides: portfolio, portfolioLoading, portfolioReady
Required: AllPortfoliosMixin
-->
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    // Properties set by router:
    portfolio_id: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      __portfolioById: 'portfolio/getById'
    }),
    portfolio () {
      return this.__portfolioById(this.portfolio_id)
    },
    portfolioLoading () {
      return this.portfolio !== undefined && this.portfolio.__details_loading
    },
    portfolioReady () {
      return this.portfolio !== undefined && this.portfolio.__details_loaded
    }
  },
  created () {
    this.__loadPortfolioDetailsById(this.portfolio_id)
  },
  watch: {
    allPortfolios () {
      this.__loadPortfolioDetailsById(this.portfolio_id)
    },
    portfolio_id () {
      this.__loadPortfolioDetailsById(this.portfolio_id)
    }
  },
  methods: {
    ...mapActions({
      __loadPortfolioDetailsById: 'portfolio/loadDetailsById'
    })
  }
}
</script>
