<i18n>
{
  "de": {
    "newUserTitle": "Benutzer hinzufügen",
    "roleLabel": "Berechtigung",
    "userOptionsLabel": "Benutzer",
    "submitButtonLabel": "Speichern",
    "portfolioNotFound": "Portfolio nicht gefunden",
    "roleLabels": {
      "USER": "USER",
      "EDITOR": "EDITOR",
      "MANAGER": "MANAGER"
    }
  }
}
</i18n>

<template>
  <main-layout :ready="portfolioReady && allUsersReady" :portfolio="portfolio" class="admin-add-user-to-portfolio-page">
    <template #default>
      <p v-if="error">{{ JSON.stringify(error) }}</p>
      <h1>{{ $t('newUserTitle') }}</h1>
      <form>
        <label for="user-options">{{ $t('userOptionsLabel') }}</label>
        <v-select id="user-options" v-model="userId" :options="userOptions" :reduce="user => user.id" />

        <label for="role-options">{{ $t('roleLabel') }}</label>
        <v-select id="role-options" v-model="role" :options="roleOptions" :reduce="role => role.key" />

        <button-wrapper>
          <button class="button" :disabled="!submitButtonEnabled" @click.prevent="onSubmit">{{ $t('submitButtonLabel') }}</button>
        </button-wrapper>
      </form>
    </template>
  </main-layout>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'
import PortfolioUsersMixin from '@/pages/vuex-mixins/PortfolioUsersMixin'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin'

import { mapActions } from 'vuex'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioUsersMixin, // Provides: portfolio.users, portfolioUsersLoading, portfolioUsersReady
    AdminAllUsersMixin // Provides: allUsers, allUsersReady
  ],
  components: {
    ButtonWrapper,
    MainLayout
  },
  data () {
    return {
      userId: null,
      role: null,
      error: null
    }
  },
  computed: {
    roleOptions () {
      return [
        {
          'key': 'USER',
          'label': this.$t('roleLabels.USER')
        },
        {
          'key': 'EDITOR',
          'label': this.$t('roleLabels.EDITOR')
        },
        {
          'key': 'MANAGER',
          'label': this.$t('roleLabels.MANAGER')
        }
      ]
    },
    portfolioUsers () {
      return this.portfolio && this.portfolio.users
    },
    userOptions () {
      const users = this.allUsers || []
      const existingUsers = this.portfolioUsers || []
      return users.filter((user) => !existingUsers.find((eu) => eu.id === user.id)).map((user) => {
        return { label: `${user.first_name} ${user.last_name} (${user.company_name})`, id: user.id }
      })
    },
    submitButtonEnabled () {
      return Boolean(this.userId) && this.role !== null
    }
  },
  methods: {
    ...mapActions({
      addPortfolioUser: 'portfolio/addPortfolioUser'
    }),
    async onSubmit () {
      try {
        await this.addPortfolioUser({ portfolioId: this.portfolio_id, userId: this.userId, role: this.role })
        this.$router.push({ name: 'adminPortfolioDetails', params: { portfolio_id: this.portfolio_id } })
      } catch (error) {
        this.error = error
      }
    }
  }
}
</script>

<style>
.admin-add-user-to-portfolio-page form {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-m);
  column-gap: var(--spacing-l);
}

.admin-add-user-to-portfolio-page form button {
  grid-column-end: -1;
  justify-self: start;
}
</style>
